var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "w-full",
        staticStyle: { "border-bottom": "1px solid #dadce0" },
      },
      [
        _c("div", { staticClass: "flex mb-2 mt-4 w-full" }, [
          _c("div", { staticClass: "bg-grid-color-secondary mr-2 w-full" }, [
            _vm.showQuestionNumber
              ? _c("div", { staticClass: "m-1 flex gap-2" }, [
                  _c("b", [
                    _vm._v(_vm._s(_vm.$t("questao-this-index", [this.index]))),
                  ]),
                  _vm.showQuestionScore
                    ? _c("label", [
                        _vm._v(
                          _vm._s(
                            _vm.item.data.score
                              ? "(" +
                                  _vm.$t("valor") +
                                  ": " +
                                  _vm.formattedScore +
                                  ")"
                              : ""
                          )
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _c("div", {
              directives: [
                {
                  name: "html-safe",
                  rawName: "v-html-safe",
                  value: _vm.title,
                  expression: "title",
                },
              ],
              staticClass: "editor-content ck-content content-break-word ml-3",
            }),
          ]),
        ]),
      ]
    ),
    _vm.showAnswerSection
      ? _c("div", { staticClass: "answer" }, [
          _c("div", { staticClass: "w-full mt-8" }, [
            _c("div", { staticClass: "flex mb-2 w-full" }, [
              _c("div", { staticClass: "bg-grid-color-secondary mr-2" }, [
                _c("h4", { class: _vm.statusClass }, [
                  _vm._v(_vm._s(_vm.$t("resposta_uppercase")) + ":"),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "flex sm:w-full" }, [
            _c(
              "div",
              { staticClass: "bg-grid-color-secondary mr-2 mb-2 pt-2 w-full" },
              [
                _c("div", {
                  directives: [
                    {
                      name: "html-safe",
                      rawName: "v-html-safe",
                      value: _vm.value,
                      expression: "value",
                    },
                  ],
                  class: _vm.statusClass,
                }),
              ]
            ),
          ]),
          !_vm.isEmpty(_vm.item.feedback || _vm.feedback)
            ? _c("h5", { staticClass: "mt-8 border-bottom" })
            : _vm._e(),
          !_vm.isEmpty(_vm.item.feedback || _vm.feedback)
            ? _c("h5", { staticClass: "mt-8 border-bottom" }, [
                _vm._v(_vm._s(_vm.$t("feedback-do-professor"))),
              ])
            : _vm._e(),
          !_vm.isEmpty(_vm.item.feedback || _vm.feedback)
            ? _c("div", { staticClass: "flex sm:w-full" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "bg-grid-color-secondary mr-2 mb-2 pt-2 w-full",
                  },
                  [
                    _c("div", {
                      directives: [
                        {
                          name: "html-safe",
                          rawName: "v-html-safe",
                          value: _vm.item.feedback || _vm.feedback,
                          expression: "item.feedback || feedback",
                        },
                      ],
                    }),
                  ]
                ),
              ])
            : _vm._e(),
          _vm.invalidated
            ? _c("h5", { staticClass: "mt-8 border-bottom" })
            : _vm._e(),
          _vm.invalidated
            ? _c("h5", { staticClass: "mt-8 text-danger" }, [
                _vm._v(_vm._s(_vm.$t("justificativa-da-anulacao"))),
              ])
            : _vm._e(),
          _vm.invalidated
            ? _c("div", { staticClass: "flex sm:w-full" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "bg-grid-color-secondary mr-2 mb-2 pt-2 w-full",
                  },
                  [
                    _c("div", {
                      directives: [
                        {
                          name: "html-safe",
                          rawName: "v-html-safe",
                          value: _vm.invalidated,
                          expression: "invalidated",
                        },
                      ],
                    }),
                  ]
                ),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }