var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "w-full",
        staticStyle: { "border-bottom": "1px solid #dadce0" },
      },
      [
        _c("div", { staticClass: "flex mb-2 mt-2 w-full" }, [
          _c("div", { staticClass: "bg-grid-color-secondary mr-2 w-full" }, [
            _vm.showQuestionNumber
              ? _c("div", { staticClass: "m-1 flex gap-2" }, [
                  _c("b", [
                    _vm._v(_vm._s(_vm.$t("questao-this-index", [this.index]))),
                  ]),
                  _vm.showQuestionScore
                    ? _c("label", [
                        _vm._v(
                          _vm._s(
                            _vm.item.data.score
                              ? "(" +
                                  _vm.$t("valor") +
                                  ": " +
                                  _vm.formattedScore +
                                  ")"
                              : ""
                          )
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _c("div", {
              directives: [
                {
                  name: "html-safe",
                  rawName: "v-html-safe",
                  value: _vm.title,
                  expression: "title",
                },
              ],
              staticClass: "content-break-word question-title ml-3",
            }),
          ]),
        ]),
      ]
    ),
    _c("div", { staticClass: "options" }, [
      _vm.inlineOptions
        ? _c(
            "div",
            { staticClass: "pt-2" },
            _vm._l(_vm.options, function (option) {
              return _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: {
                        content: _vm.isSelected(option.id)
                          ? _vm.item.correction
                            ? "Resposta correta"
                            : "Resposta Incorreta"
                          : "",
                        delay: { show: 500, hide: 100 },
                      },
                      expression:
                        "{\n          content: (isSelected(option.id) ? item.correction ? 'Resposta correta' : 'Resposta Incorreta' : ''),\n          delay: { show: 500, hide: 100 }\n        }",
                    },
                  ],
                  key: _vm.key,
                  class: _vm.borderClass(option),
                },
                [
                  _c(
                    "div",
                    { staticClass: "inline-flex relative items-center" },
                    [
                      option.selected
                        ? _c(
                            "div",
                            { staticClass: "absolute top-0 right-0" },
                            [
                              _c("feather-icon", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: {
                                      content: "Gabarito do professor",
                                      delay: { show: 500, hide: 100 },
                                    },
                                    expression:
                                      "{\n                content: 'Gabarito do professor',\n                delay: { show: 500, hide: 100 }\n              }",
                                  },
                                ],
                                class: _vm.teacherMark(option),
                                attrs: {
                                  icon: "CheckIcon",
                                  svgClasses: "stroke-2 text-success w-8 h-8",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("vs-button", {
                        class: _vm.isSelected(option.id)
                          ? _vm.item.correction
                            ? "choose-button checked-button"
                            : "choose-button text-danger"
                          : "choose-button",
                        attrs: {
                          type: "filled",
                          icon: _vm.isSelected(option.id)
                            ? "radio_button_checked"
                            : "radio_button_unchecked",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", {
                    directives: [
                      {
                        name: "html-safe",
                        rawName: "v-html-safe",
                        value: option.label,
                        expression: "option.label",
                      },
                    ],
                    staticClass: "inline w-full",
                  }),
                ]
              )
            }),
            0
          )
        : _c(
            "div",
            _vm._l(_vm.chunkedOptions, function (opts) {
              return _c(
                "div",
                { key: _vm.key, staticClass: "grid grid-cols-3 gap-4 mt-4" },
                _vm._l(opts, function (option) {
                  return _c(
                    "vs-row",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: {
                            content: _vm.isSelected(option.id)
                              ? _vm.item.correction
                                ? "Resposta correta"
                                : "Resposta Incorreta"
                              : "",
                            delay: { show: 500, hide: 100 },
                          },
                          expression:
                            "{\n          content: (isSelected(option.id) ? item.correction ? 'Resposta correta' : 'Resposta Incorreta' : ''),\n          delay: { show: 500, hide: 100 }\n        }",
                        },
                      ],
                      key: option,
                      class: _vm.borderClass(option),
                    },
                    [
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-type": "flex",
                            "vs-justify": "center",
                            "vs-align": "center",
                            "vs-w": "12",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "min-width": "100px",
                                "min-height": "100px",
                                "max-width": "50%",
                                cursor: "pointer",
                              },
                            },
                            [
                              option.image && option.image.src
                                ? _c("img", {
                                    attrs: {
                                      src: option.image.src,
                                      width: "100",
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-type": "flex",
                            "vs-justify": "center",
                            "vs-align": "center",
                            "vs-w": "12",
                          },
                        },
                        [
                          option.selected
                            ? _c("feather-icon", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: {
                                      content: "Gabarito do professor",
                                      delay: { show: 500, hide: 100 },
                                    },
                                    expression:
                                      "{\n                content: 'Gabarito do professor',\n                delay: { show: 500, hide: 100 }\n              }",
                                  },
                                ],
                                class: _vm.teacherMark(option),
                                attrs: {
                                  icon: "CheckIcon",
                                  svgClasses:
                                    "stroke-current success w-6 h-6 check-current",
                                },
                              })
                            : _vm._e(),
                          _c("vs-button", {
                            class: _vm.isSelected(option.id)
                              ? "choose-button checked-button"
                              : "choose-button",
                            attrs: {
                              type: "filled",
                              icon: _vm.isSelected(option.id)
                                ? "radio_button_checked"
                                : "radio_button_unchecked",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-type": "flex",
                            "vs-justify": "center",
                            "vs-align": "center",
                            "vs-w": "12",
                          },
                        },
                        [
                          _c("div", {
                            directives: [
                              {
                                name: "html-safe",
                                rawName: "v-html-safe",
                                value: option.label,
                                expression: "option.label",
                              },
                            ],
                          }),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                1
              )
            }),
            0
          ),
    ]),
    !_vm.isEmpty(_vm.item.feedback || _vm.feedback)
      ? _c("h5", { staticClass: "mt-8 border-bottom" })
      : _vm._e(),
    !_vm.isEmpty(_vm.item.feedback || _vm.feedback)
      ? _c("h5", { staticClass: "mt-8 border-bottom" }, [
          _vm._v(_vm._s(_vm.$t("feedback-do-professor"))),
        ])
      : _vm._e(),
    !_vm.isEmpty(_vm.item.feedback || _vm.feedback)
      ? _c("div", { staticClass: "flex sm:w-full" }, [
          _c(
            "div",
            { staticClass: "bg-grid-color-secondary mr-2 mb-2 pt-2 w-full" },
            [
              _c("div", {
                directives: [
                  {
                    name: "html-safe",
                    rawName: "v-html-safe",
                    value: _vm.item.feedback || _vm.feedback,
                    expression: "item.feedback || feedback",
                  },
                ],
              }),
            ]
          ),
        ])
      : _vm._e(),
    _vm.invalidated
      ? _c("h5", { staticClass: "mt-8 border-bottom" })
      : _vm._e(),
    _vm.invalidated
      ? _c("h5", { staticClass: "mt-8 text-danger" }, [
          _vm._v(_vm._s(_vm.$t("justificativa-da-anulacao"))),
        ])
      : _vm._e(),
    _vm.invalidated
      ? _c("div", { staticClass: "flex sm:w-full" }, [
          _c(
            "div",
            { staticClass: "bg-grid-color-secondary mr-2 mb-2 pt-2 w-full" },
            [
              _c("div", {
                directives: [
                  {
                    name: "html-safe",
                    rawName: "v-html-safe",
                    value: _vm.invalidated,
                    expression: "invalidated",
                  },
                ],
              }),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }