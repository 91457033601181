
<template>
  <div>
    <div class="w-full" style="border-bottom: 1px solid #dadce0;">
      <div class="flex mb-2 mt-4 w-full">
        <div class="bg-grid-color-secondary mr-2 w-full">
          <div class="m-1 flex gap-2" v-if="showQuestionNumber">
            <b>{{ $t('questao-this-index', [this.index]) }}</b>
            <label v-if="showQuestionScore">{{item.data.score ? `(${$t('valor')}: ` + formattedScore + ')': ''}}</label>
          </div>
          <div v-html-safe="title" class="editor-content ck-content content-break-word ml-3"></div>
        </div>
      </div>
    </div>

    <div class="answer" v-if="showAnswerSection">
      <div class="w-full mt-8">
        <div class="flex mb-2 w-full">
          <div class="bg-grid-color-secondary mr-2">
            <h4 :class="statusClass">{{ $t('resposta_uppercase') }}:</h4>
          </div>
        </div>
      </div>

      <div class="flex sm:w-full">
        <div class="bg-grid-color-secondary mr-2 mb-2 pt-2 w-full">
          <div v-html-safe="value" :class="statusClass"></div>
        </div>
      </div>

      <h5 class="mt-8 border-bottom" v-if="!isEmpty(item.feedback || feedback)"></h5>
      <h5 class="mt-8 border-bottom" v-if="!isEmpty(item.feedback || feedback)">{{ $t('feedback-do-professor') }}</h5>
      <div class="flex sm:w-full" v-if="!isEmpty(item.feedback || feedback)">
        <div class="bg-grid-color-secondary mr-2 mb-2 pt-2 w-full">
          <div v-html-safe="item.feedback || feedback"></div>
        </div>
      </div>

      <h5 class="mt-8 border-bottom" v-if="invalidated"></h5>
      <h5 class="mt-8 text-danger" v-if="invalidated">{{ $t('justificativa-da-anulacao') }}</h5>
      <div class="flex sm:w-full" v-if="invalidated">
        <div class="bg-grid-color-secondary mr-2 mb-2 pt-2 w-full">
          <div v-html-safe="invalidated"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    item: {
      type: Object,
      default: () => ({
        feedback: ''
      })
    },
    showAnswerSection: {
      type: Boolean,
      default: true
    },
    showQuestionNumber:{
      type: Boolean,
      default: false
    },
    showQuestionScore:{
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    value: '',
    feedback: null
  }),
  computed: {
    title() {
      const title = this.item.data.title ? this.item.data.title : this.$t('sem-titulo')
      return title
    },
    statusClass() {
      return (this.item.correction === true || this.item.correction === false) ? this.item.correction ? 'success editor-content ck-content' : 'error editor-content ck-content' : ''
    },
    invalidated() {
      const invalidated = this._.get(this.item, 'data.invalidated.justification', false)
      if (invalidated !== false) {
        return invalidated
      }

      return false
    },
    formattedScore() {
      const score = this.item.data.score
      return score ? parseFloat(score).toFixed(2).replace('.', ',') : ''
    }
  },
  methods: {

  },
  beforeMount() {
    this.value = this.item.answer ? this.item.answer : ''
    if (!this.isEmpty(this.value)) {
      this.value = this.value.replace(/(?:\r\n|\r|\n)/g, '<br />')
    }
    this.feedback = this.item.feedback
  },
  beforeDestroy() {
    this.destroyChilds(this)
  }
}
</script>

<style>
.success {
  color: green !important;
}

.error {
  color: red !important;
}
</style>
