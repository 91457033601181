<template>
  <div>
    <div class="w-full" style="border-bottom: 1px solid #dadce0;">
      <div class="flex mb-2 mt-2 w-full">
        <div class="bg-grid-color-secondary mr-2">
          <div class="m-1 flex gap-2" v-if="showQuestionNumber">
            <b>{{ $t('questao-this-index', [this.index]) }}</b>
            <label v-if="showQuestionScore">{{item.data.score ? `(${$t('valor')}: ` + formattedScore + ')': ''}}</label>
          </div>
          <div class="question-title ml-3" v-html-safe="title"></div>
        </div>
      </div>
    </div>

    <div class="options">
      <div class="pt-2" v-if="inlineOptions">

        <div
          v-for="option in options"
          :key="key"
          :class="borderClass(option)"
          v-tooltip="{
            content: (isSelected(option.id) ? item.correction ? 'Resposta correta' : 'Resposta Incorreta' : ''),
            delay: { show: 500, hide: 100 }
          }"
        >
          <div class="inline-flex relative items-center">
            <div v-if="option.selected" class="absolute bottom-0 right-0">
              <feather-icon
                icon="CheckIcon"
                svgClasses="stroke-2 text-success w-8 h-8"
                :class="teacherMark(option)"
                v-tooltip="{
                  content: 'Gabarito do professor',
                  delay: { show: 500, hide: 100 }
                }"
              />
            </div>

            <vs-button
              type="filled"
              :icon="
                isSelected(option.id)
                  ? 'check_box'
                  : 'check_box_outline_blank'
              "
              :class="
                isSelected(option.id)
                  ? item.correction
                    ? 'choose-button checked-button'
                    : 'choose-button text-danger'
                  : 'choose-button'
              "
            ></vs-button>
          </div>
          <div class="inline w-full" v-html-safe="option.label"></div>
        </div>

      </div>

      <div v-else>
        <div
          class="grid grid-cols-3 gap-4 mt-4"
          v-for="opts in chunkedOptions"
          :key="key"
        >
          <vs-row
            v-for="option in opts"
            :key="option"
            :class="borderClass(option)"
            v-tooltip="{
            content: (isSelected(option.id) ? item.correction ? 'Resposta correta' : 'Resposta Incorreta' : ''),
            delay: { show: 500, hide: 100 }
          }"
          >
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
            >
              <div
                style="min-width: 100px; min-height: 100px; max-width: 50%; cursor: pointer;"
              >
                <img
                  v-if="option.image && option.image.src"
                  :src="option.image.src"
                  width="100"
                />
              </div>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
            >
              <feather-icon
                v-if="option.selected"
                icon="CheckIcon"
                svgClasses="stroke-current success w-6 h-6"
                :class="teacherMark(option)"
                v-tooltip="{
                  content: 'Gabarito do professor',
                  delay: { show: 500, hide: 100 }
                }"
              />
              <vs-button
                type="filled"
                :icon="
                  isSelected(option.id)
                    ? 'check_box'
                    : 'check_box_outline_blank'
                "
                :class="
                  isSelected(option.id)
                    ? 'choose-button checked-button check-current'
                    : 'choose-button'
                "
              ></vs-button>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
            >
              <div v-html-safe="option.label"></div>
            </vs-col>
          </vs-row>
        </div>
      </div>
    </div>

    <h5 class="mt-8 border-bottom" v-if="!isEmpty(item.feedback || feedback)"></h5>
    <h5 class="mt-8 border-bottom" v-if="!isEmpty(item.feedback || feedback)">{{ $t('feedback-do-professor') }}</h5>
    <div class="flex sm:w-full" v-if="!isEmpty(item.feedback || feedback)">
      <div class="bg-grid-color-secondary mr-2 mb-2 pt-2 w-full">
        <div v-html-safe="item.feedback || feedback"></div>
      </div>
    </div>

    <h5 class="mt-8 border-bottom" v-if="invalidated"></h5>
    <h5 class="mt-8 text-danger" v-if="invalidated">{{ $t('justificativa-da-anulacao') }}</h5>
    <div class="flex sm:w-full" v-if="invalidated">
      <div class="bg-grid-color-secondary mr-2 mb-2 pt-2 w-full">
        <div v-html-safe="invalidated"></div>
      </div>
    </div>

  </div>
</template>

<script>

import MediaService from '@/services/api/MediaService'

export default {
  props: {
    item: {
      type: Object,
      default: () => ({
        feedback: ''
      })
    },
    highlightCorrectBackground: {
      type: Boolean,
      default: true
    },
    showQuestionNumber:{
      type: Boolean,
      default: false
    },
    showQuestionScore:{
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    options: []
  }),
  computed: {
    chunkedOptions() {
      return _.chunk(this.options, 3)
    },
    inlineOptions() {
      let inline = true
      if (this.options) {
        for (let index = 0; index < this.options.length; index++) {
          const option = this.options[index]
          if (option.image !== null) {
            inline = false
            break
          }
        }
      }
      return inline
    },
    title() {
      const title = this.item.data.title ? this.item.data.title : this.$t('sem-titulo')
      return title
    },
    invalidated() {
      const invalidated = this._.get(this.item, 'data.invalidated.justification', false)
      if (invalidated !== false) {
        return invalidated
      }

      return false
    },
    formattedScore() {
      const score = this.item.data.score
      return score ? parseFloat(score).toFixed(2).replace('.', ',') : ''
    }
  },
  methods: {
    borderClass(option) {
      const baseClass = 'flex items-center py-1 px-2 sm:py-auto'
      if (this.highlightCorrectBackground) {
        return option.chosen
          ? this.item.correction
            ? `${baseClass} bg-green-light bg-opacity-25`
            : `${baseClass} bg-red-light bg-opacity-25`
          : `${baseClass}`
      } else {
        return baseClass
      }
    },
    isSelected(optionId) {
      return !this.isEmpty(this.item.chosen_option_id) && this.item.chosen_option_id.includes(optionId)
    },
    teacherMark(option) {
      const classes = 'z-500'
      return !option.selected ? `${classes} invisible` : `${classes}`
    },
  },
  beforeMount() {
    this.mediaService = MediaService.build(this.$vs)

    if (this.item) {
      this.options =
        this.item.data && this.item.data.options ? this.item.data.options : []
      this.options.forEach(element => {
        element.chosen = false
      })

      for (let index = 0; index < this.options.length; index++) {
        const element = this.options[index]
        if (!this.isEmpty(this.item.chosen_option_id) && this.item.chosen_option_id.includes(element.id)) {
          element.chosen = true
        }
      }
    }
  },
  beforeDestroy() {
    this.destroyChilds(this)
  }
}
</script>

<style>
.success-border {
  border: 1px solid green;
  border-radius: 8px;
  padding: 5px;
}

.error-border {
  border: 1px solid red;
  border-radius: 8px;
  padding: 5px;
}
.check-current{
  margin-top: 2px;
  margin-right: 2px;
}
</style>
